.grid{

    display: grid;
    padding: 3px;
    text-align: center;
    align-items: center;
    justify-content: center;
    
    @media screen and (min-width: 320px){
       /* background-color: blue; */ 
       grid-template-columns: repeat(1, 1fr);
    
    };
    
    @media screen and (min-width: 480px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(1, 1fr);
    
    };
    
    @media screen and (min-width: 552px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(2, 1fr);
    
    };
    
    @media screen and (min-width: 624px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(2, 1fr);
    
    }; 
    
    @media  screen and (min-width: 750px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(2, 1fr);
    
     };
    
    @media  screen and (min-width: 960px){
       /* background-color: green; */
       grid-template-columns: repeat(2, 1fr);
       
    };
    
    @media  screen and (min-width: 1024px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(2, 1fr);
    
     };
    
    @media  screen and (min-width: 1328px){
        grid-template-columns: repeat(2, 1fr);
       /*  background-color: red;  */
    };
    
    @media  screen and (min-width: 1365px){
        grid-template-columns: repeat(2, 1fr);
       /*  background-color: red;  */
    };
    
    @media  screen and (min-width: 1440px){
        grid-template-columns: repeat(2, 1fr);
       /*  background-color: red;  */
    };
    
    
    .col{
    margin-bottom: 3px;
    padding: 3px;
    text-align: center;
    align-items: center;
    justify-content: center;
    }
    }
    
    #rectagulo{
    height: 50px;
    max-width: 200px;
    background: white;
    align: center;
    
    }


    #titdescargar{
     color: rgb(0, 191, 255)
    }